<template>
  <div class="addHousing">
    <div class="addHousing_box w-1400 margin-auto">
      <div class="business_tag flexs p-l-100">
        <el-breadcrumb replace separator-class="el-icon-arrow-right">
          <el-breadcrumb-item to="/housing?type=1">房源管理</el-breadcrumb-item>
          <el-breadcrumb-item to="/estate?type=1">小区</el-breadcrumb-item>
          <el-breadcrumb-item :to="`/estateDetail?id=${from.id}`"
            >详情</el-breadcrumb-item
          >
          <el-breadcrumb-item>编辑</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="addHousing_head">
        <!-- <ul class="addHousing_head_list flex">
          <template v-for="(item, index) in headList">
            <li
              v-if="(type == 2 && index != 0) || type == 1"
              class="center"
              @click="changeHead(item.type)"
              :key="index"
              :class="{ active: item.type == from.fang_type }"
            >
              {{ item.name }}
            </li>
          </template>
        </ul> -->
        <div class="addHousing_head_main b-c-f">
          <div class="addHousing_head_title flexs p-t-30 p-b-30">
            <span>基本信息</span>
            <span>（{{ userInfo.name }}）</span>
          </div>
          <el-form
            :model="from"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <ul class="addHousing_head_ul fl flex-wrap flex-between">
              <li>
                <el-form-item label="小区编号:" prop="id" label-width="120">
                  <el-input
                    v-model="from.id"
                    disabled
                    placeholder="请输入编号"
                  ></el-input>
                </el-form-item>
              </li>

              <li>
                <el-form-item label="所在区域:" prop="city" label-width="120">
                  <el-cascader
                    size="large"
                    clearable
                    :options="regionList"
                    v-model="areaList"
                    placeholder="请选择所在区域"
                    @change="handleChangeCity"
                  >
                  </el-cascader>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="小区名称:" prop="name" label-width="120">
                  <el-input
                    v-model="from.name"
                    placeholder="请输入小区名称"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="小区地址:"
                  prop="address"
                  label-width="120"
                >
                  <el-input
                    @input="changeAddress"
                    v-model="from.address"
                    placeholder="请输入详细地址"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="小区首字母:"
                  label-width="120"
                >
                  <el-input
                    v-model="from.letter"
                    placeholder="请输入小区首字母"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="附近地铁:">
                  <el-select
                    v-model="ditie"
                    @change="changeMetro"
                    multiple
                    placeholder="请选择附近地铁"
                  >
                    <el-option
                      v-for="item in metroList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="地铁站:">
                  <el-select
                    @change="changeDitieAhan"
                    v-model="ditie_zhan"
                    multiple
                    placeholder="请选择地铁站"
                  >
                    <el-option
                      v-for="item in subwayList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li class="ditie-ipt">
                <el-form-item
                  label="距地铁站距离:"
                  label-width="120"
                >
                  <el-input
                    v-model="from.ditie_num"
                    placeholder="请输入地铁站距离"
                  >
                  <el-button slot="append">m</el-button>
                </el-input>
                </el-form-item>
              </li>
              <li class="ditie-ipt">
                <el-form-item
                  label="距公交站距离:"
                  label-width="120"
                >
                  <el-input
                    v-model="from.gongjiao_num"
                    placeholder="请输入公交站距离"
                  >
                  <el-button slot="append">m</el-button>
                </el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="面积介绍:" label-width="120">
                  <div class="addHousing_head_ul_ipt flex">
                    <div class="estate-area-input">
                      <el-input placeholder="请输入" v-model="from.min_mianji">
                        <el-button slot="append">㎡</el-button>
                      </el-input>
                    </div>
                    <div class="estate-area-line"></div>
                    <div class="estate-area-input">
                      <el-input placeholder="请输入" v-model="from.max_mianji">
                        <el-button slot="append">㎡</el-button>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="售卖均价:"
                  prop="junjia_price"
                  label-width="120"
                >
                  <el-input
                    placeholder="请输入售卖均价"
                    v-model="from.junjia_price"
                  >
                  </el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="售卖区间价:"
                  prop="qujian_price"
                  label-width="120"
                >
                  <div class="addHousing_head_ul_ipt flex">
                    <div class="estate-area-input">
                      <el-input
                        placeholder="请输入"
                        v-model="from.min_qujian_price"
                      >
                        <el-button slot="append">元/㎡</el-button>
                      </el-input>
                    </div>
                    <div class="estate-area-line"></div>
                    <div class="estate-area-input">
                      <el-input
                        placeholder="请输入"
                        v-model="from.max_qujian_price"
                      >
                        <el-button slot="append">元/㎡</el-button>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="租赁均价:"
                  prop="zu_price"
                  label-width="120"
                >
                  <el-input
                    placeholder="请输入租赁均价"
                    v-model="from.zu_price"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="租赁区间价:" prop="name" label-width="120">
                  <div class="addHousing_head_ul_ipt flex">
                    <div class="estate-area-input">
                      <el-input
                        placeholder="请输入"
                        v-model="from.min_zu_qujian_price"
                      >
                        <el-button slot="append">元/月</el-button>
                      </el-input>
                    </div>
                    <div class="estate-area-line"></div>
                    <div class="estate-area-input">
                      <el-input
                        placeholder="请输入"
                        v-model="from.max_zu_qujian_price"
                      >
                        <el-button slot="append">元/月</el-button>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="开发商名称:"
                  prop="developers"
                  label-width="120"
                >
                  <el-input
                    v-model="from.developers"
                    placeholder="请输入开发商名称"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="小区物业:" prop="wuye" label-width="120">
                  <el-input
                    placeholder="请输入小区物业"
                    v-model="from.wuye"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                
                <el-form-item label="物业类型:" label-width="120">
                  <el-select
                    v-model="from.wuye_lei"
                    placeholder="请选择物业类型"
                  >
                    <el-option
                      v-for="item in zuHousingTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="物业费:"
                  prop="property_fee"
                  label-width="120"
                >
                  <el-input
                    placeholder="请输入物业费(元)"
                    v-model="from.property_fee"
                  >
                  </el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="绿化率:"
                  prop="greening_rate"
                  label-width="120"
                >
                  <el-input
                    placeholder="请输入绿化率(%)"
                    v-model="from.greening_rate"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="容积率:"
                  prop="plot_ratio"
                  label-width="120"
                >
                  <el-input
                    placeholder="请输入容积率(%)"
                    v-model="from.plot_ratio"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item
                  label="是否有电梯:"
                  label-width="120"
                >
                  <el-select
                    v-model="from.dianti"
                    placeholder="请选择是否有电梯"
                  >
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="商圈:" prop="shangquan" label-width="120">
                  <el-select
                    :disabled="!from.city"
                    v-model="from.shangquan"
                    clearable
                    placeholder="请选择商圈"
                  >
                    <el-option
                      v-for="item in shangquanList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="梯户比例:" label-width="120" prop="ti">
                  <div
                    class="addHousing_head_ul_ipt flex"
                    v-for="(item, index) in doorList"
                    :key="index"
                  >
                    <div class="estate-floor-input">
                      <el-input
                        v-model="item.ti"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="">梯</div>
                    <div class="estate-floor-input">
                      <el-input
                        v-model="item.hu"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="">户</div>
                    <div class="estate-floor-input">
                      <el-input
                        v-model="item.percent"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="estate-floor-add" @click="changeDoor(index)">
                      <img
                        v-if="index == 0"
                        class="estate-floor-add-icon"
                        src="../../assets/image/personage/add1.png"
                      />
                      <img
                        v-else
                        class="estate-floor-add-icon"
                        src="../../assets/image/personage/dele.png"
                      />
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="完善楼栋:" label-width="120" prop="ti">
                  <div
                    class="addHousing_head_ul_ipt flex"
                    v-for="(item, index) in [...oldLouList, ...newLouList]"
                    :key="index"
                  >
                    <div class="estate-lou-input">
                      <el-input
                        :readonly="item.disabled"
                        v-model="item.lou"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="">楼栋</div>
                    <div class="estate-lou-input">
                      <el-input
                        :readonly="item.disabled"
                        v-model="item.unit"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="">单元</div>
                    <div class="estate-lou-input">
                      <el-input
                        :readonly="item.disabled"
                        type="number"
                        v-model.number="item.level"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="">楼层</div>
                    <div class="estate-lou-input">
                      <el-input
                        :readonly="item.disabled"
                        type="number"
                        v-model.number="item.hu"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                    <div class="">每层户数</div>
                    <div class="estate-floor-add" @click="changeLou(index)">
                      <img
                        v-if="index == 0"
                        class="estate-floor-add-icon"
                        src="../../assets/image/personage/add1.png"
                      />
                      <img
                        v-if="index > 0 && !item.disabled"
                        class="estate-floor-add-icon"
                        src="../../assets/image/personage/dele.png"
                      />
                    </div>
                  </div>
                  <div class="lou-item-tip">楼栋信息保存后不可修改，请谨慎填写<span style="color: red;">*</span></div>
                </el-form-item>
              </li>
              <!-- <li>
                <el-form-item label="建成时间:" prop="jian_time">
                  <el-date-picker
                    @change="changeJianTime"
                    v-model="from.jian_times"
                    type="date"
                    placeholder="请选择建成时间"
                    format="yyyy-MM-dd"
                    value-format="timestamp"
                  >
                  </el-date-picker>
                </el-form-item>
              </li> -->
              <li>
                <el-form-item label="竣工时间:" prop="jun_time">
                  <el-date-picker
                    v-model="from.jun_time"
                    type="date"
                    placeholder="请选择竣工时间"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="物业电话:">
                  <el-input
                    v-model="from.wuye_mobile"
                    placeholder="请输入物业电话"
                  ></el-input>
                </el-form-item>
              </li>
              <!-- <li>
                <el-form-item label="物业费单价:">
                  <el-input
                    v-model="from.wuye_price"
                    placeholder="请输入物业费单价"
                  ></el-input>
                </el-form-item>
              </li> -->
              <li>
                <el-form-item label="用电:">
                  <el-select
                    v-model="from.dianfei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in electricityList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="电费单价:">
                  <el-input
                    v-model="from.dian_price"
                    placeholder="请输入电费单价"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="燃气单价:">
                  <el-input
                    v-model="from.ranqi_price"
                    placeholder="请输入燃气单价"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="统一供暖:">
                  <el-select
                    v-model="from.gongnuan"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="暖气单价:">
                  <el-input
                    v-model="from.nuanqi_price"
                    placeholder="请输入燃气单价"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="供水:">
                  <el-select
                    v-model="from.gongshui"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in electricityList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="水费单价:">
                  <el-input
                    v-model="from.shui_price"
                    placeholder="请输入水费单价"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                  <el-form-item label="车位总数量:">
                    <el-input
                      v-model="from.chewei_shu"
                      placeholder="请输入车位总数量"
                    ></el-input>
                  </el-form-item>
                </li>
              <li>
                  <el-form-item label="车位配比:">
                    <el-input
                      v-model="from.che_bi"
                      placeholder="请输入车位比例"
                    ></el-input>
                  </el-form-item>
                </li>
              <li>
                  <el-form-item label="车位月管理费:">
                    <el-input
                      v-model="from.che_guan_price"
                      placeholder="请输入车位管理费"
                    ></el-input>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item label="每月车位费:">
                    <el-input
                      v-model="from.che_price"
                      placeholder="请输入车位费"
                    ></el-input>
                  </el-form-item>
                </li>
              <li>
                  <el-form-item label="总户数:">
                    <el-input
                      v-model="from.hus"
                      placeholder="请输入总户数"
                    ></el-input>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item label="户型区间:">
                    <el-input
                      v-model="from.hu_qujian"
                      placeholder="请输入户型区间"
                    ></el-input>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item label="权属类别:">
                    <el-select
                    v-model="from.quanshu_lei"
                    placeholder="请选择权属类别"
                  >
                    <el-option
                      v-for="item in ownershipTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item label="产权年限:">
                    <el-input
                      v-model="from.chanquan_nian"
                      placeholder="请输入产权年限"
                    ></el-input>
                  </el-form-item>
                </li>
                <!-- <li>
                  <el-form-item label="建筑类型:">
                    <el-input
                      v-model="from.jian_lei"
                      placeholder="请输入建筑类型"
                    ></el-input>
                  </el-form-item>
                </li> -->
              <li>
                <el-form-item label="楼盘标签:">
                  <div class="houseing_ul flexs flex-wrap">
                  <div
                    class="houseing_ul_li center"
                    @click="changeTag(item)"
                    :class="{ active: item.checked }"
                    v-for="(item, index) in tagList"
                    :key="index"
                  >
                    {{ item.text }}
                  </div>
                </div>
                </el-form-item>
                
              </li>
            </ul>
          </el-form>
          <div class="houseSelect m-t-30 m-b-30">
            <div class="houseSelect_txt m-b-20">户型介绍</div>
            <div class="estate-layout-hd">
              <div class="hd-l">
                <span
                  @click="changeRoom(index)"
                  class="hd-li"
                  :class="{ active: index == roomIndex }"
                  v-for="(item, index) in classList"
                  :key="index"
                  >{{ item.name }}</span
                >
              </div>
              <div class="hd-r">
                <div
                  class="estate-layout-hd-btn"
                  @click="$refs.add.open(from.id)"
                >
                  <img
                    class="icon m-r-10"
                    src="../../assets/image/houseing/house61.png"
                  />
                  <span>新增户型</span>
                </div>
              </div>
            </div>
            <div class="estate-layout-bd p-t-20">
              <div
                class="bd-item"
                v-for="(item, index) in huxingList"
                :key="index"
              >
                <div class="bd-item-con">
                  <img class="item-img" :src="item.image" />
                  <div class="item-txt">
                    <div class="flex flex-between">
                      <div class="t">{{ item.huxing }}</div>
                      <div class="t-r flex">
                        <div
                          class="item-edit flex m-r-20"
                          @click="changeHuxing(1, item)"
                        >
                          <img
                            class="t-icon m-r-10"
                            src="../../assets/image/houseing/house62.png"
                          />
                          <span>编辑</span>
                        </div>
                        <div
                          class="item-delete flex"
                          @click="changeHuxing(2, item)"
                        >
                          <img
                            class="t-icon m-r-10"
                            src="../../assets/image/houseing/house64.png"
                          />
                          <span>删除</span>
                        </div>
                      </div>
                    </div>
                    <div class="c">
                      面积{{ item.mianji }}m²/{{ item.chaoxiang }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="houseSelect m-t-30 m-b-30">
            <div class="houseSelect_txt m-b-20">房源简介</div>
            <div class="estate-my-last">
              <div class="estate-my-last-item m-b-25">
                <div class="t">小区介绍:</div>
                <div class="estate-my-last-text m-t-15">
                  <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="请输入内容"
                    v-model="from.xiaoqu_content"
                  >
                  </el-input>
                </div>
              </div>
              <div class="estate-my-last-item m-b-25">
                <div class="t m-t-15">综合介绍:</div>
                <div class="estate-my-last-text m-t-15">
                  <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="请输入内容"
                    v-model="from.zong_content"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="addHousing_head_main_btn center">
            <el-button @click="addHousing">保存</el-button>
          </div>
        </div>
        <div class="addHousing_head_main m-t-20 b-c-f">
          <div class="addHousing_head_title flexs p-t-30 p-b-30">
            <span>图片</span>
            <span>（{{ userInfo.name }}）</span>
          </div>
          <ul class="addHousing_head_ul flex flex-wrap">
            <ul
              class="addHousing_head_ul addHousing_head_img addHousing_head_uls"
            >
              <li>
                <div class="addHousing_head_ul_txt m-r-20">封面图:</div>
                <div class="addHousing_head_img_ul">
                  <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    action="https://xin.xinyoujudichan.com/api/common/upload"
                    :on-success="uploadCoverImg"
                  >
                    <img
                      v-if="from.fengmian_image"
                      :src="from.fengmian_image"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">轮播图:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <el-upload
                    multiple
                    class=""
                    action="https://xin.xinyoujudichan.com/api/common/upload"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreview"
                    :on-remove="handleRemove">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <!-- <div
                    class="addHousing_head_img_ul_li avatar-uploader m-r-20"
                    v-for="(item, index) in fang_lunbo_images"
                    :key="index"
                  >
                    <img :src="item" alt="" class="avatar" />
                    <img
                      @click="cancelUpload('fang_lunbo_images', index)"
                      src="../../assets/image/publice/cancel.png"
                      class="close"
                      alt=""
                    />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input
                      type="file"
                      @input="uploadVideoImg($event, 'fang_lunbo_images')"
                    />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div> -->
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">视频:</div>
                <div class="addHousing_head_img_ul">
                  <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    action="https://xin.xinyoujudichan.com/api/common/upload"
                    :on-success="uploadFengMianTu"
                  >
                    <video
                      class="video"
                      :src="from.vediofile"
                      v-if="from.vediofile"
                    ></video>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </li>
            </ul>
          </ul>
          <div class="addHousing_head_main_btn center">
            <el-button @click="saveImg">保存</el-button>
          </div>
        </div>
      </div>
    </div>

    <!--新增户型弹出 start-->
    <build-add ref="add" @success="gethuXing"></build-add>
    <!--新增户型弹出 end-->
    <!--  -->
    <build-edit-huxing ref="huxing" @success="gethuXing"></build-edit-huxing>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import index from "../../common/housing";
import { regionData, CodeToText } from "element-china-area-data";
import BuildAdd from "../../components/BuildAdd";
import BuildEditHuxing from "../../components/BuildEditHuxing.vue";
export default {
  components: { BuildAdd, BuildEditHuxing },
  // mixins: [index],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "city", "region"]),
  },
  data() {
    var tihu = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入梯户比例"));
      } else {
        callback();
      }
    };
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      value: "", //
      areaList: [], //区域列表
      classList: [], //
      roomIndex: 0, //
      from: {
        id: "",
        name: "",
        city: "",
        address: "",
        shangquan: "",
        mianji: "",
        min_mianji: "",
        max_mianji: "", //
        junjia_price: "",
        qujian_price: "",
        min_qujian_price: "",
        max_qujian_price: "",
        zu_price: "",
        zu_qujian_price: "",
        min_zu_qujian_price: "",
        max_zu_qujian_price: "",
        wuye: "",
        developers: "",
        ti: "",
        lat: "",
        log: "",
        spans: "",
        letter: "",
        xiaoqu_content: "",
        zong_content: "",
        jian_time: "", //竣工时间时间戳
        jian_times: "", //竣工时间年月日(自己用)
        fengmian_image: "",
        vediofile: "",
        lunbo_images: "",
        property_fee: "", //物业费
        greening_rate: "", //绿化率
        plot_ratio: "", //容积率
        ditie: "", //地铁
        ditie_zhan: "", //地铁站
        wuye_mobile: "", //物业电话
        wuye_price: "", //物业费单价
        wuye_price_yue: "", //物业费月
        dian_price: "", //电费
        ranqi_price: "", //燃气费
        nuanqi_price: "", //暖气费
        shui_price: "", //水费
        che_price: "", //车位费每月
        che_que: "", //是否有车位
        che_guan_price: "", //车位管理费
        hus:'',//总户数
        che_bi:'',//车位比例
        hu_qujian:'',//户型区间
        jun_time:'',//竣工时间
        dianti:'',//是否有电梯
        wuye_lei:'',//物业类型
        quanshu_lei:'',//权属类别
        chanquan_nian:'',//产权年限
        jian_lei:'',//建筑类型
        gongnuan:'',//统一供暖
        gongshui:'',//供水
        dianfei:'',//用电
        chewei_shu:'',//车位总数量
        gongjiao_num:'',//公交距离
        ditie_num:'',//地铁距离

        // 新增
        l5: '', // 
      },
      ditie: [], //附近地铁
      ditie_zhan: [], //地铁站
      doorList: [
        {
          ti: "",
          hu: "",
          percent: "",
        },
      ], //梯户比例

      // 如果之前没有信息（也就是第一次提交楼栋信息），可以加也可以减，如果之前有楼栋信息，可以加但不可以减（这里说的加、减是前端界面的加减，不是提交到后端后端去加、减）
      // 之前的楼栋信息 [{ lou, unit, level, hu, disabled }]
      oldLouList: [],
      // 完善楼栋 [[楼栋, 单元, 楼层, 每层户数]]
      // 新的楼栋信息
      newLouList: [{ lou: '', unit: '', level: '', hu: '' }],

      fang_lunbo_images: [], //轮播图
      fang_vediofile: [], //视频
      shangquanList: [], //商圈
      huxingList: [], //户型列表
      houseTagList: [],
      tagList: [], //标签
      metroList: [], //附近地铁
      subwayList: [], //地铁站
      rules: {
        id: [{ required: true, message: "请输入编号", trigger: "blur" }],
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入小区地址", trigger: "blur" },
        ],
        city: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        shangquan: [
          { required: true, message: "请选择商圈", trigger: "change" },
        ],
        junjia_price: [
          { required: true, message: "请输入售卖均价", trigger: "blur" },
        ],
        zu_price: [
          { required: true, message: "请输入租赁均价", trigger: "blur" },
        ],
        developers: [
          { required: true, message: "请输入开发商名称", trigger: "blur" },
        ],
        wuye: [{ required: true, message: "请输入小区物业", trigger: "blur" }],
        property_fee: [
          { required: true, message: "请输入物业费", trigger: "blur" },
        ],
        greening_rate: [
          { required: true, message: "请输入绿化率", trigger: "blur" },
        ],
        plot_ratio: [
          { required: true, message: "请输入容积率", trigger: "blur" },
        ],
        // jian_time: [
        //   { required: true, message: "请输入竣工时间", trigger: "blur" },
        // ],
        mianji: [
          { required: true, message: "请输入面积区间", trigger: "blur" },
        ],
        ti: [{ required: true, validator: tihu, trigger: "blur" }],
      },
    };
  },
  methods: {
    handlePictureCardPreview (e) {
      this.fang_lunbo_images.push(e.data.fullurl)
    },
    handleRemove (e) {
      let data = []
      this.fang_lunbo_images.forEach(item=>{
        if (item != e.response.data.fullurl) data.push(item)
      })
      this.fang_lunbo_images = data
    },
    //选择地铁
    changeMetro(e) {
      if (e.length) {
        let ditie = [];
        e.forEach((item) => {
          this.metroList.forEach((em) => {
            if (item == em.id) {
              ditie.push(em.name);
            }
          });
        });
        this.from.ditie = ditie.join(",");
        if (this.from.ditie) {
          this.$axios
            .diTieZhan({
              id: e.join(","),
            })
            .then((res) => {
              this.subwayList = res.data;
            });
        }
      }
    },
    //选择地铁站
    changeDitieAhan(e) {
      if (e.length) {
        let ditie = [];
        e.forEach((item) => {
          this.subwayList.forEach((em) => {
            if (item == em.id) {
              ditie.push(em.name);
            }
          });
        });
        this.from.ditie_zhan = ditie.join(",");
      }
    },
    changeJianTime(e) {
      this.from.jian_time = e / 1000;
    },
    goHome() {
      this.$router.go(-1);
    },
    uploadVideoImg(response, name) {
      this.$axios.uploadFile(response.target.files[0]).then((res) => {
        this[name].push(res.fullurl);
      });
    },
    cancelUpload(fang_vediofile, index) {
      this[fang_vediofile].splice(index, 1);
    },
    changeDoor(index) {
      index == 0
        ? this.doorList.push({ ti: "", hu: "", percent: "" })
        : this.doorList.splice(index, 1);
    },
    changeLou(index) {
      index == 0
        ? this.newLouList.push({ lou: '', unit: '', level: '', hu: '' })
        : this.newLouList.splice(index - this.oldLouList.length, 1);
    },
    changeTag(item) {
      item.checked = !item.checked;
    },
    //编辑删除户型
    changeHuxing(type, item) {
      if (type == 1) {
        this.$refs.huxing.open(item);
      } else {
        this.$axios.xiaoQuHuDel({ id: item.id }).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.gethuXing();
        });
      }
    },
    //上传封面图
    uploadFengMianTu(e) {
      this.from.vediofile = e.data.fullurl;
    },
    uploadCoverImg(file) {
      this.from.fengmian_image = file.data.fullurl;
    },
    uploadBannerImg(file) {
      this.fang_lunbo_images.push(file.data.fullurl);
    },
    getSize(min, max, min_name, max_name) {
      if (Number(min) < Number(max)) {
        return min + "," + max;
      } else {
        let min_price = min;
        let max_price = max;
        this.from[max_name] = min_price;
        this.from[min_name] = max_price;
        return this.from[min_name] + "," + this.from[max_name];
      }
    },
    //保存图片
    saveImg() {
      this.$axios
        .xiaoQuImages({
          id: this.from.id,
          fengmian_image: this.from.fengmian_image,
          vediofile: this.from.vediofile,
          lunbo_images: this.fang_lunbo_images.join(","),
        })
        .then((res) => {
          this.$message({ message: res.msg, type: "success" });
          this.$router.go(-1);
        });
    },
    //获取经纬度
    changeAddress() {
      if (this.from.address && this.from.city) {
        this.$axios
          .address({
            address: this.from.city.split("/").join("") + this.from.address,
          })
          .then((res) => {
            let location = res.data.result.location;
            this.from.log = location.lng;
            this.from.lat = location.lat;
          });
      }
    },
    //添加出售房源
    addHousing() {
      if (this.from.min_mianji && this.from.max_mianji) {
        this.from.mianji = this.getSize(
          this.from.min_mianji,
          this.from.max_mianji,
          "min_mianji",
          "max_mianji"
        );
      }
      if (this.from.min_qujian_price && this.from.max_qujian_price) {
        this.from.qujian_price = this.getSize(
          this.from.min_qujian_price,
          this.from.max_qujian_price,
          "min_qujian_price",
          "max_qujian_price"
        );
      }
      if (this.from.min_zu_qujian_price && this.from.max_zu_qujian_price) {
        this.from.zu_qujian_price = this.getSize(
          this.from.min_zu_qujian_price,
          this.from.max_zu_qujian_price,
          "min_zu_qujian_price",
          "max_zu_qujian_price"
        );
      }

      let spans = [];
      this.tagList.forEach((item) => {
        item.checked ? spans.push(item.text) : "";
      });
      this.from.spans = spans.join(",");
      let door = [];
      this.doorList.forEach((item) => {
        if (item.ti && item.hu && item.percent) {
          let txt = item.ti + "/" + item.hu + "/" + item.percent;
          door.push(txt);
        }
      });
      if (!door.length)
        return this.$message({
          message: "请输入梯户比例",
          type: "warning",
        });
      this.from.ti = door.join(",");

      const l5Arr = []
      for (const index in [...this.oldLouList, ...this.newLouList]) {
        const item = [...this.oldLouList, ...this.newLouList][index]
        if (!item.lou || !item.unit || !item.level || !item.hu) return this.$message({ message: "请完善楼栋", type: "warning" });
        if (isNaN(+item.level) || +item.level <= 0 || parseInt(item.level) < parseFloat(item.level) || isNaN(+item.hu) || +item.hu <= 0 || parseInt(item.hu) < parseFloat(item.hu) ) return this.$message({ message: "楼层和每层户数必须是大于 0 的整数", type: "warning" });
        l5Arr.push(item.lou + '-' + item.unit + '-' + item.level + '-' + item.hu)
      }
      this.from.l5 = l5Arr.join(',')

      // console.log({ ...this.from })
      // return

      this.$axios.xiaoQuEdit(this.from).then((res) => {
        this.$message({ message: res.msg, type: "success" });
        this.$router.go(-1);
      });
    },
    changeHead(index) {
      this.from.fang_type = index;
    },
    //获取详情
    getDetails() {
      this.$axios.xiaoQuDetail({ id: this.from.id }).then((res) => {
        let data = res.data.city.split("/");
        let areaList = [];
        this.regionList.forEach((em) => {
          if (em.label == data[0]) {
            areaList.push(em.value);
            em.children.forEach((item) => {
              if (item.label == data[1]) {
                areaList.push(item.value);
                item.children.forEach((it) => {
                  if (it.label == data[2]) {
                    areaList.push(it.value);
                  }
                });
              }
            });
          }
        });
        for (let index = 0; index < res.data.spans.length; index++) {
          for (let i = 0; i < this.tagList.length; i++) {
            if (this.tagList[i].text == res.data.spans[index]) {
              this.tagList[i].checked = true;
            }
          }
        }
        this.areaList = areaList;

        let door = [];
        res.data.ti.forEach((item) => {
          door.push({
            ti: item.split("/")[0],
            hu: item.split("/")[1],
            percent: item.split("/")[2],
          });
        });
        this.doorList = door.length ? door : [{ ti: "", hu: "", percent: "" }];

        res.data.shangquan = res.data.shangquan
          ? Number(res.data.shangquan)
          : "";
        let {
          id,
          name,
          city,
          address,
          shangquan,
          junjia_price,
          zu_price,
          wuye,
          developers,
          ti,
          spans,
          xiaoqu_content,
          zong_content,
          jian_time,
          fengmian_image,
          lng,
          lat,
          property_fee,
          greening_rate,
          plot_ratio,
          letter,
          wuye_mobile,
          wuye_price,
          wuye_price_yue,
          dian_price,
          ranqi_price,
          nuanqi_price,
          shui_price,
          che_price,
          che_que,
          che_guan_price,
          hus,
          che_bi,
          hu_qujian,
          jun_time,
          dianti,
          wuye_lei,
          quanshu_lei,
          chanquan_nian,
          jian_lei,
          gongnuan,
          gongshui,
          dianfei,
          chewei_shu,
          ditie_num,
          gongjiao_num,
          l5,
        } = res.data;

        this.from = {
          ...this.from,
          id,
          name,
          city,
          address,
          shangquan,
          junjia_price,
          zu_price,
          wuye,
          developers,
          ti,
          spans,
          xiaoqu_content,
          zong_content,
          jian_time,
          fengmian_image,
          lng,
          lat,
          property_fee,
          greening_rate,
          plot_ratio,
          letter,
          wuye_mobile,
          wuye_price,
          wuye_price_yue,
          dian_price,
          ranqi_price,
          nuanqi_price,
          shui_price,
          che_price,
          che_que,
          che_guan_price,
          hus,
          che_bi,
          hu_qujian,
          jun_time,
          dianti,
          wuye_lei,
          quanshu_lei,
          chanquan_nian,
          jian_lei,
          gongnuan,
          gongshui,
          dianfei,
          chewei_shu,
          ditie_num,
          gongjiao_num,
        };
        if (l5 && l5.length) {
          this.oldLouList = l5.split(',').map(item => ({ 
            lou: item.split('-')[0],
            unit: item.split('-')[1],
            level: +item.split('-')[2],
            hu: +item.split('-')[3],
            disabled: true,
           }))
          this.newLouList = [];
        } else {
          this.newLouList = [{ lou: '', unit: '', level: '', hu: '' }];
        }

        // 地铁 S
        if (res.data.city) {
          let city = res.data.city.split("/");
          let details = res.data;
          this.$axios
            .diTie({
              city: city[0] + "/" + city[1],
            })
            .then((res) => {
              this.metroList = res.data;
              let dities = [];
              for (let i = 0; i < details.ditie.length; i++) {
                for (let j = 0; j < res.data.length; j++) {
                  if (details.ditie[i] == res.data[j].name) {
                    dities.push(res.data[j].id);
                  }
                }
              }
              this.ditie = dities;
              if (dities.join(",")) {
                this.$axios
                  .diTieZhan({
                    id: dities.join(","),
                  })
                  .then((res) => {
                    let ditie_zhans = [];
                    this.subwayList = res.data;
                    for (let i = 0; i < details.ditie_zhan.length; i++) {
                      for (let j = 0; j < res.data.length; j++) {
                        if (details.ditie_zhan[i] == res.data[j].name) {
                          ditie_zhans.push(res.data[j].id);
                        }
                      }
                    }
                    this.ditie_zhan = ditie_zhans;
                  });
              }
            });
        }
        this.from.ditie = res.data.ditie.join(',')
        this.from.ditie = res.data.ditie_zhan.join(',')
        // 地铁 E

        this.from.mianji = res.data.mianji.join(",");
        this.from.min_mianji = !res.data.mianji.length
          ? ""
          : Number(res.data.mianji[0]);
        this.from.max_mianji = !res.data.mianji.length
          ? ""
          : Number(res.data.mianji[1]);
        this.from.qujian_price = res.data.qujian_price.join(",");
        this.from.min_qujian_price = !res.data.qujian_price.length
          ? ""
          : Number(res.data.qujian_price[0]);
        this.from.max_qujian_price = !res.data.qujian_price.length
          ? ""
          : Number(res.data.qujian_price[1]);
        this.from.zu_qujian_price = res.data.zu_qujian_price.join(",");
        this.from.min_zu_qujian_price = !res.data.zu_qujian_price.length
          ? ""
          : Number(res.data.zu_qujian_price[0]);
        this.from.max_zu_qujian_price = !res.data.zu_qujian_price.length
          ? ""
          : Number(res.data.zu_qujian_price[1]);
        this.fang_lunbo_images = res.data.lunbo_images;
        this.from.vediofile = res.data.vediofile ? res.data.vediofile[0] : "";
        this.from.jian_times = res.data.jian_time * 1000;
        this.getshangQuan();
        this.changeAddress();
      });
    },
    //获取商圈列表
    getshangQuan() {
      this.$axios
        .shangQuan({
          city: this.from.city,
        })
        .then((res) => {
          this.shangquanList = res.data;
        });
    },
    //获取户型
    gethuXing() {
      this.$axios
        .xiaoQuHuXing({
          id: this.from.id,
          type: this.classList[this.roomIndex].name,
        })
        .then((res) => {
          this.huxingList = res.data;
        });
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      this.from.city = data.join("/");
      this.getshangQuan();
      this.changeAddress();
    },
    //切换户型
    changeRoom(index) {
      this.roomIndex = index;
      this.gethuXing();
    },
    getHuxing() {
      this.$axios.huXing().then((res) => {
        this.classList = res.data;
        this.gethuXing();
      });
    },
    //小区标签
    getxiaoQuBiaoQian() {
      this.$axios.xiaoQuBiaoQian().then((res) => {
        res.data.forEach((item) => {
          item.checked = false;
        });
        this.tagList = res.data;
        this.getDetails();
      });
    },
  },
  created() {
    this.from.id = this.$route.query.id;
    this.getxiaoQuBiaoQian();
    this.getHuxing();
  },
};
</script>

<style lang="less" scoped>
.estate-my-last {
  .estate-my-last-item {
    .t {
      font-size: 16px;
    }
    .estate-my-last-text {
      background: #fafafa;
      padding: 15px 20px;
      border-radius: 8px;
      /deep/ .el-textarea__inner {
        padding: 0;
        border: none;
        outline: none;
        background: none;
        padding: 0;
      }
    }
  }
}
.houseSelect .addHousing_head_ul {
  padding: 0px;
}
.addHousing_head_ul_ipt {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.estate-layout-bd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -20px;
  .bd-item {
    width: 50%;
  }
  .bd-item-con {
    box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    margin: 10px 20px;
    display: flex;
    .item-img {
      width: 120px;
      height: 90px;
      border: 1px solid #ebebeb;
    }
    .item-txt {
      cursor: pointer;
      width: calc(100% - 130px);
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .t {
        font-size: 20px;
        line-height: 1.6;
      }
      .t-r {
        color: #999;
        display: flex;
      }
      .t-icon {
        width: 18px;
        height: 18px;
      }
      .c {
        font-size: 18px;
        color: #999;
        line-height: 1.6;
      }
    }
  }
}
.estate-floor-add {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.estate-layout-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .hd-l {
    display: flex;
  }
  .hd-li {
    display: flex;
    height: 32px;
    cursor: pointer;
    background: #f5f5f5;
    border-radius: 4px;
    padding: 0 15px;
    align-items: center;
    font-size: 16px;
    color: #666;
    margin: 0 10px 10px 0px;
  }
  .active {
    background: #d4e3ff;
    color: #3273f6;
  }
  .estate-layout-hd-btn {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    background: #3273f6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    .icon {
      width: 22px;
      height: 22px;
    }
  }
}
.addHousing_head_list {
  padding: 0 225px;
  background: #3273f6;
  li {
    cursor: pointer;
    height: 64px;
    color: #ebebeb;
    font-size: 18px;
  }
  .active {
    color: #ffffff;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 24px;
      height: 2px;
      background: #ffffff;
    }
  }
}
.addHousing_head_title {
  padding-left: 100px;
  span {
    &:first-child {
      font-size: 18px;
      padding-left: 14px;
      position: relative;
      &::before {
        content: "";
        width: 4px;
        height: 20px;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background: #3273f6;
        position: absolute;
      }
    }
    &:last-child {
      color: #3273f6;
      font-size: 16px;
    }
  }
}
.addHousing_head_ul {
  padding: 0 100px;
  li {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    /deep/ .el-form-item {
      display: flex;
    }
    .addHousing_head_ul_txt {
      width: 140px;
      color: #666666;
      font-size: 14px;
      text-align: right;
    }
    
    /deep/ .el-input__inner {
      width: 400px;
      height: 40px;
      border: 1px solid #cccccc;
    }
    .estate-area-input {
      width: 180px;
    }
    .estate-area-line {
      width: 16px;
      height: 1px;
      background: #ccc;
      margin: 0 10px;
    }
    /deep/ .estate-area-input .el-input__inner {
      width: 100%;
    }
    .estate-floor-input {
      width: 115px;
    }
    /deep/ .estate-floor-input .el-input__inner {
      width: 100%;
    }
    .estate-lou-input {
      width: 59px;
    }
    /deep/ .estate-lou-input .el-input__inner {
      width: 100%;
      padding: 0 8px;
    }
    .building {
      /deep/ .el-input__inner {
        width: 96px;
        margin-right: 5px;
      }
    }
    .time {
      /deep/ .el-input {
        width: 400px !important;
      }
    }
    .houseing_ul {
      width: 400px;
    }
    .houseing_ul_li {
      height: 28px;
      color: #666666;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;
      background: #ebebeb;
      border-radius: 3px;
      margin: 0 10px 10px 0;
    }
    .active {
      color: #3273f6;
      background: #e1ebff;
    }
  }
  .ditie-ipt {
    /deep/ .el-input__inner {
      width: 343px !important;
    }
  }
}
.lou-item-tip {
  color: #999999;
  font-size: 12px;
  margin-top: -10px;
  text-align: right;
}
.addHousing_head_uls {
  padding: 0;
  li {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .addHousing_head_ul_txt {
      width: 60px !important;
      flex-shrink: 0;
    }
  }
}
.addHousing_head_img {
  li {
    align-items: flex-start;
    justify-content: flex-start;
    .addHousing_head_img_ul {
      .addHousing_head_img_ul_li {
        position: relative;
        border: 1px dashed #d9d9d9;
        background: #fbfdff;
        border-radius: 6px;
        margin-bottom: 20px;
        &:hover {
          border-color: #409eff;
        }
        .close {
          top: -12px;
          right: -12px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          position: absolute;
        }
        input {
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
          cursor: pointer;
          position: absolute;
          border-radius: 6px;
        }
      }
    }
  }
}
.required {
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.houseSelect {
  padding: 0 100px;
  .houseSelect_txt {
    // width: 140px;
    color: #3273f6;
    font-size: 16px;
    font-weight: 600;
    // text-align: right;
  }
  .houseSelect_ul {
    li {
      display: flex;
      width: 580px;
      padding: 20px;
      margin-bottom: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
      border-radius: 4px;
      position: relative;
      .houseSelect_ul_select {
        top: 20px;
        right: 20px;
        position: absolute;
        width: 24px;
        height: 24px;
      }
      .houseSelect_img {
        img {
          width: 120px;
          height: 90px;
        }
        margin-right: 10px;
      }
      .houseSelect_mian {
        height: 90px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      }
      .houseSelect_name {
        font-size: 20px;
      }
      .houseSelect_area {
        color: #666666;
        font-size: 18px;
      }
    }
  }
  .houseSelect_rich {
    background: #fafafa;
    border-radius: 8px;
    padding: 20px;
  }
}
.addHousing_head_main_btn {
  padding: 60px 0;
  /deep/ .el-button {
    width: 680px;
    height: 56px;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 8px;
  }
}
.fitment_time {
  /deep/ .el-input {
    width: 400px;
  }
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #fbfdff;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar,
.video {
  width: 150px;
  height: 150px;
  display: block;
}
/deep/ .el-date-editor.el-input {
  width: 400px;
}
</style>
