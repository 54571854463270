<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>户型添加</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="interview_main">
        <el-form
          ref="form"
          :rules="rules"
          class="form"
          :model="form"
          label-width="100px"
        >
          <el-form-item label="户型类别:" prop="type">
            <el-select @change="changeClass" v-model="form.type" placeholder="请选择户型类别">
              <el-option
                v-for="item in classList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="户型:" prop="huxing">
            <el-select :disabled="!form.type" v-model="form.huxing" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :key="item.title"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="面积:" prop="mianji">
            <el-input v-model="form.mianji" placeholder="请输入面积"></el-input>
          </el-form-item>
          <el-form-item label="朝向:">
            <el-radio-group v-model="form.chaoxiang" size="small">
              <el-radio-button :label="item.label" v-for="(item,index) in orientationList" :key="index"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="户型图:">
            <el-upload
              class="avatar-uploader"
              action="https://xin.xinyoujudichan.com/api/common/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess">
              <img v-if="form.image" :src="form.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="interview_main_footer center">
          <el-button @click="submit('form')">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        id: "",
        type:'',
        huxing: "",
        mianji: "",
        chaoxiang: "",
        image: ""
      },
      typeList:[],
      imageUrl:'',
      classList: [],
      imageList:[],//
      rules: {
        mianji: [{ required: true, message: "请输入面积", trigger: "blur" }],
        type: [{ required: true, message: "请选择户型类别", trigger: "blur" }],
        huxing: [{ required: true, message: "请选择户型", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleAvatarSuccess (e) {
      this.form.image = e.data.fullurl
    },
    changeClass (e) {
      this.classList.forEach(element => {
        if (element.id == e) {
          this.typeList = element.shi_name
        }
      });
      this.form.huxing = ''
    },
    //提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.xiaoQuHuAdd(this.form).then(res=>{
            this.$message({
                message: res.msg,
                type: "success",
              });
              this.dialogVisible = false;
              this.$emit("success");
              this.form.huxing = ''
              this.form.type = ''
              this.form.mianji = ''
              this.form.chaoxiang = ''
              this.form.image = ''
          })
        } else {
          this.$message({
            message: "请填写未填项",
            type: "warning",
          });
          return false;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    open(id) {
      this.form.id = id
      this.dialogVisible = true;
      this.gethuXing();
    },
    //获取门店列表
    gethuXing() {
      this.$axios.huXing().then((res) => {
        this.classList = res.data;
      });
    },
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
</style>
